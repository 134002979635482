import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { ApiService }                   from  '../api.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators, ValidatorFn, FormArray }       from '@angular/forms';

@Component({
  selector: 'app-tabs-training-add',
  templateUrl: './tabs-training-add.page.html',
  styleUrls: ['./tabs-training-add.page.scss'],
})
export class TabsTrainingAddPage implements OnInit {

  courtType: any;
  booking: any;
  submitted = false;
  loading = true;
  options: any;

  form: FormGroup;
  classItem: {training_type_id: number}[] = [];

  @ViewChild(IonContent, {static: true}) content: IonContent;


  constructor(private modalController: ModalController,
      private alertController: AlertController,
  		private navParams: NavParams,
      private apiService: ApiService,
      private fb: FormBuilder) {}

  get f() { return this.form.controls; }

  ngOnInit() {
    this.loading = true;

    this.booking          = this.navParams.get('data');
    this.booking['end_hours'] = moment(this.booking.start_hours).add(this.booking.duration*60, 'minutes').format('hh:mmA')

    // this.classes = this.fb.array([]);


    this.form = this.fb.group({
      booking_id:       [ this.booking.id,        [Validators.required]],
      duration:         [ this.booking.duration,  [Validators.required]],
      team_id:          [ '',                     [Validators.required]],
      training_classes: [ this.classItem,         [Validators.required, Validators.minLength(1)]]
    });

    this.classItem = []; 
    this.index();
  }

  index() {


    this.apiService.trainingOptions(this.booking.id).subscribe(data => {
          // this.submitted = false;  
        this.loading = false;
        console.log(data.data);
        if (data.status == true) {
          this.options = data.data;;
        } else {
          alert(data.message);
        }
      });
  }

  create() {
    this.submitted = true;
    if(this.form.valid) {

      console.log(this.form.value);

      this.apiService.createTraining(this.form.value).subscribe(data => {
          // this.submitted = false;  
        this.loading = false;
          // console.log(data.data);
        if (data.status == true) {
          this.dismissModal(data);
        } else {
          alert(data.message);
        }
      });
      this.submitted = false;
    }
  }



  onClassChange(object, event) {

    if(event.target.checked)
      this.classItem.push({
        'training_type_id' : object['id'],
      });
    else {
      this.classItem = this.classItem.filter(item => item.training_type_id !== object['id']);
    }
    
    this.form.controls['training_classes'].setValue(this.classItem);
  }

  scrollTo(elementId: string) {
      let y = document.getElementById(elementId).offsetTop;
      this.content.scrollToPoint(0, y, 1000);
  }

  dismissModal(data = null) {

    this.modalController.dismiss({
      'dismissed': true,
      'status': data ? data: null
    });
  }

  async presentActionSheet() {

    const alert = await this.alertController.create({
      header: 'Confirm Details',
      message: 'Upon creation, notification will be sent to all team members.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Create',
          handler: () => {
            this.create();
          }
        }
      ]
    });
    await alert.present();
  }
}
